export default {
  methods: {
    fieldValidators(field){
      let vstr = "";
      // v-validate="'required|email'"  
      if(field.is_required){
        vstr += "required";
      }

      if(field.field_type == "email"){
        vstr += "|email";
      }

      if(field.max_chars > 0 || field.min_chars > 0){
        let r = "length";
        
        if(field.field_type == "tel" || field.field_type == "textdigits") r = "digits";

        if(field.max_chars == field.min_chars){
          vstr += "|" + r + ":" + field.max_chars;
        }
        else {
          if(field.min_chars > 0){
            vstr += "|min:" + field.min_chars;
          }
          if(field.max_chars > 0){
            vstr += "|max:" + field.max_chars;
          }
        }
      }

      if(field.field_type == "textdigits" && !vstr.includes('digits')){
        vstr += "|digits";
      }

      if(field.field_type == "url"){
        vstr += "|url";
      }

      if(field.field_type == "number"){
        vstr += "|min_value:0";
      }

      if(field.field_type == "percent"){
        vstr += "|min_value:0|max_value:100";
      }

      return vstr;
    },
    fieldPlaceholder(field){
     let m = "";     

      if(field.max_chars > 0 || field.min_chars > 0){
        if(field.max_chars == field.min_chars){
          m = "Ingresa " + field.min_chars + " caracteres";
        }
        else {
          m = "Ingresa entre " + field.min_chars + " y " + field.max_chars + " caracteres";
        }
      }

      if(field.field_type == "email") m = "Ingresa una dirección de correo";
      if(field.field_type == "tel") m = "Ingresa 10 dígitos";        
      if(field.field_type == "url") m = "Ingresa una url";
      if(field.field_type == "select") m = "Selecciona una opción";
      
      if(field.is_required) m = "(Requerido) " + m;
      else m = "(Opcional) " + m;
      
      return m;
    }    
  }
}